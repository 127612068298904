/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@import "./scss/main";
@import "ngx-toastr/toastr-bs5-alert";
@import "sweetalert2/src/sweetalert2.scss";

@include mat.core();

$green-palette: (
  50: #dff1ec,
  100: #b1dcce,
  200: #80c7af,
  300: #50b090,
  400: #2e9f7b,
  500: #128e67,
  600: #0e815c,
  700: #09724e,
  800: #006241,
  900: #004728,

  // ... continues to 900
  contrast: (50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    // ... continues to 900
  ),
);
$ohm-app-primary: mat.define-palette($green-palette);
$ohm-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ohm-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ohm-app-theme: mat.define-light-theme((color: (primary: $ohm-app-primary,
        accent: $ohm-app-accent,
        warn: $ohm-app-warn,
      ),
    ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ohm-app-theme);

@import url('https://fonts.googleapis.com/icon?family=Material+Icons&display=block');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,300,0,0&display=block');

body {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.app-error-text {
  color: red;
}

.check-toast {
  background-color: aquamarine;
}

.modal-context {
  justify-content: center;

  @media (min-width: 992px) {
    .modal-content {
      max-width: 900px !important;
    }
  }
}

.model-fullscreen {
  max-width: 100%;
}

.modal-context-error {
  justify-content: center;
}

input:-webkit-autofill {
  transition-delay: 99999s;
  -webkit-transition-delay: 99999s;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white !important;
}

.form-control:focus {
  background-color: #e5e5e6;
}

.form-control {
  font-size: 14px !important;
}

app-action-buttons {
  display: flex;
  height: 100%;
  align-items: center;
}

mat-label {
  font-size: 14px !important;
}

.grid-container {
  height: calc(64vh);
}

.app-basic-form {
  display: flex;
  width: 100%;
  justify-content: center;

  .row {
    margin: 4px;
  }

  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 16px;
  row-gap: 8px;
  font-size: 0.875rem;
}

.mat-mdc-floating-label {
  margin-top: -4px;
}

.mdc-floating-label--float-above {
  margin-top: 0px;
}

.mat-horizontal-stepper-wrapper {
  align-items: center;
}

.mat-horizontal-stepper-header-container {
  width: 500px;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.mat-button {
  padding: 16px 28px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  border-radius: 60px !important;
}

.action-button {
  @extend .mat-button;
  margin-top: 20px !important;
  margin-left: 12px;
  margin-right: 12px;
  width: 200px !important;
  height: 44px !important;
}

.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  font-weight: 600;

  +.mat-step-label {
    color: $app-brand-primary !important;
    font-weight: 600;
  }
}

.mat-step-header .mat-step-icon-selected {
  font-weight: 600;

  +.mat-step-label {
    color: $app-brand-primary !important;
    font-weight: 600;
  }
}

.mat-step-header .mat-step-label {
  font-size: 14px;
}

.mat-step-header .mat-step-icon {
  color: white;
}

.mat-horizontal-stepper-wrapper {
  align-items: center;
}

.mat-horizontal-stepper-header-container {
  width: 100%;

  @media screen and (min-width: 767px) {
    width: 500px;
  }
}

.mat-horizontal-content-container {
  width: 100% !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.mat-mdc-form-field-infix {
  padding-top: 12px !important;
  padding-bottom: 6px !important;
  min-height: 0px !important;

  textarea {
    padding-top: 6px;
  }
}

.mat-mdc-select-trigger {
  line-height: 2rem !important;
}

.clickable-mat-label {
  .mdc-notched-outline {
    z-index: 0 !important;
  }
}

.mat-mdc-form-field {
  .mdc-notched-outline {
    z-index: 0 !important;
  }
}

.mat-mdc-form-field-error {
  font-size: 0.75rem !important;
}

.disabled {
  .mdc-notched-outline__leading {
    border-color: rgb(0 0 0 / 6%) !important;
  }

  .mdc-notched-outline__notch {
    border-color: rgb(0 0 0 / 6%) !important;
  }

  .mdc-notched-outline__trailing {
    border-color: rgb(0 0 0 / 6%) !important;
  }
}

.tar {
  text-align: right;
}

.app-main-wrapper {
  padding-top: 12px;
  @include flexbox();
  @include flex-direction(column);
  width: 100%;
  align-items: stretch;

  .app-header {
    text-align: center;
    padding: 0 0 20px 0;
    display: flex;
    justify-content: space-between;
    column-gap: 12px;

    h3 {
      font-size: 1.25rem;
      font-weight: 500;
      margin: 0;
      color: #4b4b4b;
    }

    button {
      @extend .mat-button;
      border-color: #128e67;
    }
  }
}

.swal2-styled {
  width: 150px;
}

.mat-mdc-icon-button svg {
  vertical-align: middle !important;
}

.info-text {
  font-size: 0.875rem;
  margin-top: 0.75rem;
  margin-bottom: 1rem;

  @media screen and (min-width: 767px) {
    text-align: right;
  }
}

.pointer {
  cursor: pointer;
}

.mat-spinner {
  margin: auto;
}

.mat-progress-bar {
  height: 8px !important;
}

.visibility-hidden {
  visibility: hidden;
}

.scroll-box {
  overflow: auto;
  background:
    /* Shadow covers */
    linear-gradient(white 30%,
      rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */
    radial-gradient(farthest-side at 50% 0,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}

.small-date-picker {
  margin-top: 8px;
  margin-bottom: -1.5em;
  width: 156px;

  .mdc-notched-outline__leading {
    height: 40px;
  }

  .mdc-notched-outline__notch {
    height: 40px;
  }

  .mdc-notched-outline__trailing {
    height: 40px;
  }

  .mat-datepicker-input {
    min-height: 24px;
    height: 24px;
  }

  .mat-mdc-form-field-flex {
    height: 40px;
  }

  .mdc-floating-label {
    top: 24px !important;
  }

  .mdc-floating-label--float-above {
    top: 28px !important;
  }
}

.mdc-text-field--disabled {
  .mat-mdc-select-value {
    color: black;
  }

  .mat-mdc-select-arrow-wrapper {
    display: none;
  }
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: 9999 !important;
}

.white-icon {
  color: white;
}

.h-scroll-box {
  position: relative;
  overflow: auto;
  padding: 10px;
}

.h-scroll-box::before,
.h-scroll-box::after {
  content: "";
  position: absolute;
  top: 0;
  width: 40px;
  height: 100%;
  pointer-events: none;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.h-scroll-box::before {
  left: 0;
  background:
    radial-gradient(farthest-side at 0 50%, rgba(75, 75, 75, 0.9), rgba(0, 0, 0, 0));
}

.h-scroll-box::after {
  right: 0;
  background:
    radial-gradient(farthest-side at 100% 50%, rgba(75, 75, 75, 0.9), rgba(0, 0, 0, 0)) right;
}

.h-scroll-box.has-left-shadow::before {
  opacity: 1;
}

.h-scroll-box.has-right-shadow::after {
  opacity: 1;
}
