//custom-selection color
::selection {
	color: $white;
	background:$black;
}

::-moz-selection {
    color: $white;
    background: $black;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

/* make sure to set some focus styles for accessibility */
:focus {
    outline: 0;
}

$text-green-100: #05935d;
html,body{
  height: 100%;
}

body {
    font-family: $body-font;
    font-size: $text-md; // 14px by default
    margin: 0;
    color: $text-color;
    overflow-x: hidden;
}

.app-c-container {
    max-width: 1460px;
    margin: 0 auto;
    height: 100%;
    padding: 0 24px;
    @media screen and (max-width:480px) {
        padding: 0 15px;
    }
}

.dx-widget{
    font-family: $body-font;
    color: $text-color;
    font-size:  $text-md;
}
a {
    color: $light-blue;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
button {
    cursor: pointer;
}

b,strong {
    font-weight: $font-weight-bold;
}
img {
    border: 0; /* 1 */
    -ms-interpolation-mode: bicubic; /* 2 */
}

// Custom Scrollbar
//@include scrollbars(7px, $gray-500);

.rtl {
  direction: rtl;
}

h1{
    font-weight: 500;
    font-size: 34px;
    color: $text-color;
    margin-bottom: 5px;
}
h2{
    font-weight: 500;
    font-size: 26px;
    color: $text-color;
    margin-bottom: 5px;
}
h3{
    font-weight: 500;
    font-size: 22px;
    color: $text-color;
    margin-bottom: 5px;
}
h4{
    font-weight: 500;
    font-size: 18px;
    color: $text-color;
    margin-bottom: 5px;
}
h5{
    font-weight: 500;
    font-size: 16px;
    color: $text-color;
    margin-bottom: 5px;
}

.dash-box {
  background: $white;
  @include border-radius(8px);
  @include boxShadow(0px 4px 24px rgba(160, 166, 164, 0.19));
}
