@import "../core/mixins/box-shadow";
@import "../core/mixins/transitions";
@import "../core/mixins/border-radius";

@import "../variables/variables";

.form-control {
    padding: 6px 10px;
    height: 32px;
    min-height: 32px;
    font-size: $text-md-plus;
    font-weight: 400;
    color: $text-form-color !important;
    border: 1px solid #AFAFAF;
    background: $white;
    text-overflow: ellipsis;

    @include transition(border ease 0.3s, color ease 0.3s, box-shadow ease 0.3s, margin 0.3s ease);
    @include border-radius(3px);

    &::placeholder {
        color: $text-dark-normal;
        opacity: 1;
        /* Firefox */
    }

    &:-ms-input-placeholder {
        color: $text-dark-normal;
    }

    &::-ms-input-placeholder {
        color: $text-dark-normal;
    }

    &:focus {
        border-color: $form-control-active-border;

        @include noShadow();
    }

    &[disabled] {
        background: $form-control-disable-background;
        cursor: not-allowed;
    }

    &[readonly] {
        background: $form-control-disable-background;
        cursor: not-allowed;
    }
}

textarea {
    resize: none;
    height: auto;
}
.app-c-form--position{
    position: relative;
}

input {
    &.app-c-placeholder-truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.mandatory-field>label:after {
    content: " * ";
    color: red;
}
