$margin-base: 8px;
$margin-sm:   4px;
$margin-lg:   16px;
$margin-xl:   28px;
$margin-xxl:  48px;

$padding-xl:   28px;
$padding-lg:   8px;
$padding-base: 8px;
$padding-sm:   4px;

// $text-sm:               $font-size-base * .85;
// font-size: $text-md             $font-size-base * 1.9;
// $text-lg:               $font-size-base * 3;

// Breakpoints
// Responsive media queries

@mixin breakpoint($breakpoint) {
    $value: map-get($breakpoints, $breakpoint);
    @if $value != null {
        @media (min-width: $value) {
            @content;
        }
    }
}

$width-xs: $screen-xs;
$width-sm: $screen-sm;
$width-md: $screen-md;
$width-lg: $screen-lg;
$width-xl: 1280px;

$breakpoints: (
    // Small screen / phone
    sm: $width-sm,
    // Medium screen / tablet
    md: $width-md,
    // Large screen / desktop (980 + (12 * 2)) <= container + gutters
    lg: $width-lg,
    // Extra large screen / wide desktop
    xl: $width-xl
) !default;
