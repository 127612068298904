
$app-brand-primary:                       #006241;
$app-brand-primary-dark:                  darken(saturate($app-brand-primary, 13.48), 21.96);
$app-brand-primary-light:                 lighten(saturate($app-brand-primary, 2.24), 40.98);
$app-brand-primary-disable:               lighten(desaturate($app-brand-primary, 54.33), 68.24%);
$app-brand-secondary:                     #306842;
$app-brand-secondary-butn:                     #058856;
$app-brand-secondary-dark:                darken(saturate($app-brand-secondary, 9.52), 11.96);
$app-brand-secondary-light:               lighten(saturate(adjust-hue($app-brand-secondary, 3), 9.52), 43.33);
$app-brand-secondary-button-bg:           lighten(saturate(adjust-hue($app-brand-secondary, 3), 9.52), 43.33);
$app-brand-secondary-button-bg-dark:      darken(adjust-hue(#fffaf5, -4), 7.84);

$app-background: #DFFEEB;

// Text color
$text-color:                             #2E2C2E;
$text-dark-extra :                       $text-color;
$text-dark-medium :                      lighten(saturate($text-color, 0.69), 22.75);
$text-dark-normal :                      lighten(desaturate($text-color, 0.97), 35.49);
$text-light-normal :                     #F1F1F1;
$text-light-medium:                      lighten(desaturate($app-brand-primary, 72.31), 70.98);

$white:                                  #fff;
$black:                                  #000;
$generic-border-color:                   lighten(desaturate($app-brand-primary, 72.31), 70.98);
$text-form-color:                        darken(desaturate(adjust-hue($text-dark-extra, -200), 9.25), 24.51);
$app-signup-content-bg:                  #dff0fa;

$default-green   : #28CC56;
$default-red     : #EB5757;
$default-red-light : #FFF6F6;
$app-brand-footer-bg : $app-brand-primary;
$app-brand-footer-bg-dark : lighten(desaturate(adjust-hue($app-brand-footer-bg, 3), 9.97), 1.18);
$app-brand-footer-bg-light : lighten(desaturate($app-brand-footer-bg, 22.98), 6.86);
$app-brand-footer-bg-lighter : lighten(desaturate($app-brand-footer-bg, 28.37), 11.37);

//checkbox-theme
$app-checkbox-border:     #AFAFAF;
$app-checkbox-bg:         $white;
$app-checkbox-border-disable : lighten(desaturate($app-brand-primary, 72.31), 70.98);

// Radio Button
$app-radio-border:     $app-brand-secondary;
$app-radio-bg:         $white;
$app-radio-border-disable : lighten(desaturate($app-brand-primary, 72.31), 70.98);

//form-control

$form-control-border : lighten(desaturate($app-brand-primary, 78.65), 36.47);
$form-control-active-border : $app-brand-primary;
$form-control-disable-border : lighten(desaturate($app-brand-primary, 78.65), 36.47);
$form-control-disable-background : #F1F1F1;
$form-error: $default-red;
$form-error-background: $default-red-light;

//Tooltip

$tooltip-background : darken(desaturate($app-brand-primary, 61.10), 7.25%);
$tooltip-arrow-background : darken(desaturate($app-brand-primary, 61.10), 7.25%);

// Accordrion
$accordrion-border : lighten(desaturate($app-brand-primary, 61.41), 65.49);
$accordrion-border-active : lighten(desaturate($app-brand-primary, 78.65), 36.47);

//Modal

$modal-close-bg: lighten(desaturate($app-brand-primary, 72.31), 70.98);
$modal-close-bg-hover: lighten(desaturate($app-brand-primary, 61.41), 65.49);

//Toggle Button

$app-toggle-bg: #F1F1F1;
$app-toggle-active-bg : $default-green;
$app-toggle-inactive-border : $app-toggle-bg;

// Card
$app-card-border:                       #e6e5e5;
$app-card-header:                       #f9fbff;
$app-card-grey :                        #FCFCFC;
$app-card-shadow                    : #E3E3E3;

//Alert
$app-alert-success:                     #f0fff4;
$app-alert-danger:                      #f8eced;
$app-alert-warning:                     #fffbf0;
$app-alert-info:                        #eff8ff;
$app-alert-light:                       #f6f6f6;
$app-alert-dark:                        #eaeaea;

//General Colors
$light-blue :                            #4c71f0;
$dark-green:                             #5cb837;
$dark-red:                               #ff5630;
$secondary-border:                      #00623D;
$dark-yellow:                            #ffc84c;
$dark-blue:                              #1facfe;
$bg-green-tint-100:                      #f6fcfa;
$light-bg-cyan-shade:                   #EDFBF9;
$light-border-cyan:                     #D0D8D7;
$text-black-900:                        #11263C;
$light-gray-100:                        #C0C0C0;
$dark-green-900:                        #048051;
$text-color-gray-100:                   #657285;
$dark-green-800:                        #026842;
$dark-black-900:                        #323232;
$error-red:                             #f44336;
$gray-border-100:                       #E6F2EE;
$dark-theme-green:                       #0C442F;
$light-grey:                             #eee;
$bg-gray-100:                           #F5F6F7;
$light-cyan:                             #02BFA3;
$dark-grey:                              #a4a4a4;
$green-shade-100:                         #037742;
