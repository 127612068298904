@import "../core/mixins/box-shadow";
@import "../core/mixins/flexbox";
@import "../core/mixins/transform";
@import "../core/mixins/transitions";
@import "../core/mixins/border-radius";

@import "../variables/variables";
@import "../theme/theme";

.modal {
  --bs-modal-margin: 0px;
}

.modal-backdrop {
    &.show{
        opacity: 0.75;
    }
}
.app-c-modal{
    padding-left: 15px;
    padding-right: 15px;

    .modal-dialog{
        // height: 100%;
        margin: 0 auto;
        padding: 0;
        width: auto;

        @include flexbox();
        @include align-items(center);
        @include justify-content(center);

        .modal-content{
            background: $white;
            border: none;
            max-height: 100%;
            height: 100% !important;
            width: auto;


            @include border-radius(6px);
            @include boxShadow(0px 0px 10px rgba(35, 59, 88, 0.3));
            @include flexbox();
            @include flex-direction(column);

            .modal-header{
                border-bottom: none;
                padding: 24px 16px 10px;

                @include flexbox();
                @include align-items(center);
                @include flex-shrink(0);

                .app-l-modal-header__left{
                    @include flexbox();
                    @include align-items(center);

                    h4{
                        color: $text-color;
                        font-weight: 500;
                        font-size: 18px;
                        margin: 0;
                        padding: 0;
                        line-height: 22px;
                    }
                    .app-c-btn__round{
                        margin-left: 8px;
                    }
                }
                .app-l-modal-header__right{
                    margin-left: auto;

                    @include flex-shrink(0);
                    @include align-self(flex-start);

                }

                .app-c-modal--close{
                    background: $modal-close-bg;
                    width: 20px;
                    height: 20px;
                    color: $text-dark-normal;
                    font-size: 9px;
                    outline: none;
                    border: none;
                    padding: 0;

                    @include border-radius(3px);
                    @include flexbox();
                    @include align-items(center);
                    @include justify-content(center);
                    @include transition(background ease 0.2s);

                    &:hover{
                       background: $modal-close-bg-hover;
                    }
                }
            }
            .modal-body{
                padding: 10px 16px 20px;
                height: 100%;
                overflow: auto;
                min-height: 0;

                h4{
                    color: $text-color;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 22px;
                    margin: 0 0 8px;
                }
                p{
                    color: $text-color;
                    line-height: 16px;
                    margin: 0;
                    font-size: 14px;

                    + p{
                        margin-top: 15px;
                    }
                }
            }
            .modal-footer{
                padding: 12px 24px;
                border-top-color: $generic-border-color;

                @include flex-shrink(0);

                > *{
                    margin:0;
                }
            }
        }
    }

    &.app-c-modal--primary{
        .modal-dialog{
            .modal-content{

                .modal-header{
                    padding: 32px 32px 0 32px;

                    .close {
                        margin: 0;
                        padding: 5px;
                        position: absolute;
                        top: 12px;
                        right: 12px;
                        font-size: 12px;
                        outline: none;
                        opacity: 1;
                        color: $text-dark-normal;

                        @include transition(color ease 0.2s);

                        &:focus, &:hover{
                            color: $black;
                            outline: none;
                        }
                    }
                }
                .modal-body{
                    padding: 10px 32px 10px;
                }
                .modal-footer{
                    padding: 15px 32px 32px 32px;
                }
            }
        }
    }
}

.app-l-modal__approve{
    .modal-dialog{
        max-width: 427px;
    }
}
.app-l-modal__reject{
    .modal-dialog{
        max-width: 327px;
    }
}

.app-l-modal__create-voucher{
    .modal-dialog{
        max-width: 327px;
    }
}

.modal-lg .modal-content {
  max-width: 800px !important;
}

.modal-header {
  padding: 0 0 0 18px !important;
}
