@import "../core/mixins/box-shadow";
@import "../core/mixins/flexbox";
@import "../core/mixins/transform";
@import "../core/mixins/transitions";
@import "../core/mixins/border-radius";

@import "../variables/variables";
@import "../theme/theme";

.form-control {
  padding: 6px 10px;
  height: 32px;
  min-height: 32px;
  font-size: $text-md;
  font-weight: 400;
  color: $text-dark-extra;
  border: 1px solid $form-control-border;
  background: $white;

  @include transition(
    border ease 0.3s,
    color ease 0.3s,
    box-shadow ease 0.3s,
    margin 0.3s ease
  );
  @include border-radius(3px);

  &::placeholder {
    color: $text-dark-normal;
    opacity: 1;
    /* Firefox */
  }

  &:-ms-input-placeholder {
    color: $text-dark-normal;
  }

  &::-ms-input-placeholder {
    color: $text-dark-normal;
  }

  &:focus {
    border-color: $form-control-active-border;

    @include noShadow();
  }

  &[disabled] {
    border-color: $form-control-disable-border;
    background: $form-control-disable-background;
    cursor: not-allowed;
  }

  &[readonly] {
    border-color: $form-control-disable-border;
    background: $form-control-disable-background;
    cursor: not-allowed;
  }
}

textarea {
  resize: none;
  height: auto;
}
.app-c-form--postion {
  position: relative;
}

input {
  &.app-c-placeholder-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.form-group {
  margin-bottom: 16px;
  position: relative;

  .help-block {
    display: none;
    color: $white;
    background: $default-red;
    text-align: left;
    font-weight: 500;
    font-size: $text-sm-plus;
    position: absolute;
    bottom: 100%;
    right: 0;
    margin-bottom: 2px;
    line-height: 18px;
    padding: 1px 8px;

    @include border-radius(3px);
  }
  .app-c-form--label {
    color: $text-color;
    font-size: $text-lg;
    font-weight: 500;
    display: block;
    line-height: 14px;
    margin-bottom: 4px;
  }
  .app-c-form--value {
    font-size: 13px;
    font-weight: 400;
    color: $text-color;
    display: block;
    line-height: normal;
  }

  &.app-c-form--tex-value {
    .form-control {
      padding-right: 64px;
    }
    .app-c-form__value {
      color: $text-dark-extra;
      font-size: $text-md;
      font-weight: 400;
      position: absolute;
      line-height: normal;
      top: 7px;
      right: 7px;
    }
  }
  &.app-c-form--mandatory {
    .app-c-form--label {
      &:after {
        content: "*";
        color: $form-error;
        display: inline-block;
        font-size: 16px;
        line-height: 10px;
        margin-left: 3px;
        position: relative;
        top: 1px;
      }
    }
  }
}
