/*
    Example usage: 
    @include animation(10s, 5s, changecolour)
*/

@mixin animation($animation...){
    animation: $animation;
    -webkit-animation: $animation;
    -moz-animation: $animation;
    -ms-animation: $animation;
    -o-animation: $animation;
}